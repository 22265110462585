import React from 'react'
import { Button, Text, Flex } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Modal from './modal'

const ErrorStatusModal = ({ errorModal, setErrorModal }) => {
	const errorModalContentConfig = {
		notFound: {
			image: (
				<StaticImage
					style={{ width: 140 }}
					alt="Broken Ticket"
					src="../images/broken-ticket.png"
				/>
			),
			title: 'This Is Awkward!',
			line1: 'No reservation found.',
			line2: 'Please let a member of our team know.',
			buttonText: 'Close',
			buttonAction: () => navigate(`/`)
		},
		early: {
			image: (
				<StaticImage
					style={{ width: 107 }}
					alt="Ice Cream"
					src="../images/ice-cream.png"
				/>
			),
			title: 'Oops!',
			line1: "Looks like you're too early to check in.",
			line2: 'Please let a member of our team know.',
			buttonText: 'Close',
			buttonAction: () => navigate(`/`)
		},
		late_1: {
			image: (
				<StaticImage
					style={{ width: 107 }}
					alt="Teddy"
					src="../images/teddy.png"
				/>
			),
			title: 'Close Call!',
			line1: "Looks like you're running a little late.",
			line2: 'Please go straight to the games check-in desk.',
			buttonText: 'Continue',
			buttonAction: () => setErrorModal(false)
		},
		late_2: {
			image: (
				<StaticImage
					style={{ width: 107 }}
					alt="Ice Cream"
					src="../images/ice-cream.png"
				/>
			),
			title: 'Oops!',
			line1: "Looks like you're too late to check in.",
			line2: 'Please let a member of our team know.',
			buttonText: 'Close',
			buttonAction: () => navigate(`/`)
		},
		closed: {
			image: (
				<StaticImage
					style={{ width: 107 }}
					alt="Ice Cream"
					src="../images/ice-cream.png"
				/>
			),
			title: 'Oops!',
			line1: "Looks like you're too late to check in.",
			line2: 'Please let a member of our team know.',
			buttonText: 'Close',
			buttonAction: () => navigate(`/`)
		},
		outstandingPayment: {
			image: (
				<StaticImage
					style={{ width: 180 }}
					alt="Kiosk"
					src="../images/kiosk-screen-transaction.png"
				/>
			),
			title: 'Hmm!',
			line1: 'Looks like there may be a payment due on this booking.',
			line2: 'Please let a member of our team know.',
			buttonText: 'Refresh',
			buttonAction: () => window.location.reload()
		}
	}

	const modalContent = errorModalContentConfig[errorModal]

	if (!modalContent) {
		return null
	}

	return (
		<Modal opened={errorModal}>
			<Flex
				flexDir={'column'}
				justifyContent={'center'}
				alignItems={'center'}
				textAlign={'center'}
				gap={4}
				pt={16}
				px={8}
			>
				{modalContent.image}
				<Text fontSize="4xl" fontWeight={'900'}>
					{modalContent.title}
				</Text>
				<Text fontSize={16} fontWeight={'500'}>
					{modalContent.line1}
				</Text>
				<Text fontSize={16} fontWeight={'500'}>
					{modalContent.line2}
				</Text>
				<Button
					flexGrow={1}
					mt={10}
					variant={'link'}
					textColor={'black'}
					onClick={modalContent.buttonAction}
				>
					{modalContent.buttonText}
				</Button>
			</Flex>
		</Modal>
	)
}

export default ErrorStatusModal
